import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import DislodgedTeeth from '../../../content/images/Dislodged Teeth.gif'
import AvulsedTeeth from '../../../content/images/avulsed tooth.gif'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const TraumaticInjuriesPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Traumatic Injuries Indianapolis IN</title>
        <meta name="description" content="Endodontist Dr. Broady.Traumatic injuries are something Endodontists are uniquely qualified to treat. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <link rel="canonical" href="https://meridianendoindy.com/procedures/traumatic-injuries/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Traumatic Injuries Indianapolis IN" />
        <meta name="og:description" content="Endodontist Dr. Broady.Traumatic injuries are something Endodontists are uniquely qualified to treat. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <meta property="og:url" content="https://meridianendoindy.com/procedures/traumatic-injuries/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Traumatic Injuries Indianapolis IN" />
        <meta name="twitter:description" content="Endodontist Dr. Broady.Traumatic injuries are something Endodontists are uniquely qualified to treat. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <meta name="twitter:url" content="https://meridianendoindy.com/procedures/traumatic-injuries/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="traumatic-injuries-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="traumatic-injuries-hero-section">
          <Container>
            <div className="col-lg-6 hero-left">
              <h1 className="hero-heading primary-text">
                Traumatic Injuries
              </h1>
              <p className="hero-text">
                <strong>Dislodged Teeth</strong><br/>
                Injuries to the mouth can cause teeth to be pushed back into their sockets. Your endodontist or general dentist may reposition and stabilize your tooth.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="traumatic-injuries-section-1">
        <Container>
            <div className="col-lg-12 traumatic-injuries-container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>Dislodged Teeth</h4>
                  <p>
                    A severe injury to the mouth is traumatic. It can cause teeth to be slammed into their sockets. An endodontist or a general dentist might be able to reposition and stabilize the teeth dislodged during an accident. The area surrounding the tooth is allowed to recover from the trauma for a week or two before the endodontist administers root canal treatment. They begin by adding a medication, such as calcium hydroxide, inside the tooth. Next, a permanent root canal filling is placed inside.
                  </p>
                  <p>
                    If the tooth was pushed partially out of the socket, the endodontist or general dentist might be able to reposition and stabilize the tooth or teeth. First, the doctor will check the tooth to see if the pulp is still healthy. If there's no damage, a root canal isn't necessary. However, if the pulp is damaged or an infection is present, endodontic treatment is needed to save the tooth.
                  </p>
                </div>
                <div className="col-lg-4">
                  <img src={DislodgedTeeth} className="img-gif"/>
                  {/*}<iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/5u18zrgpgq"></iframe>
                  <p className="text-center click-here"><a href="https://fast.wistia.net/embed/iframe/5u18zrgpgq" target="_blank">Click here for high-res version</a></p>*/}
                </div>
              </div>
            </div>
        </Container>
      </section>

      <section id="traumatic-injuries-section-2">
        <Container>
            <div className="col-lg-12 traumatic-injuries-container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>Avulsed Teeth</h4>
                  <p>
                    An avulsed tooth is another way of saying it was completely knocked out of your mouth. You may be able to save the tooth if you follow the correct steps. However, you must be treated immediately. If this happens to you, keep the tooth moist. A dry or cold environment can quickly lead a lost tooth down the path towards decay and infection. Keeping it moist and at room temperature will help preserve its health for as long as possible until treatment becomes available.
                  </p>
                  <p>
                    Keeping your tooth in milk or water with a pinch of salt can help keep it fresh during the root canal process. Your endodontist may start treatment based on how far along the roots are developed, as well as if you left it out for long periods and stored it improperly.
                  </p>
                  </div>
                <div className="col-lg-4">
                  <img src={AvulsedTeeth} className="img-gif"/>
                  {/*<iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/jnlsskg62p"></iframe>
                  <p className="text-center click-here"><a href="https://fast.wistia.net/embed/iframe/jnlsskg62p" target="_blank">Click here for high-res version</a></p> */}
                </div>
              </div>
            </div>
        </Container>
      </section>

      <section id="traumatic-injuries-section-3">
        <Container>
          <div className="col-lg-12">
            <h3>Injuries in children</h3>
            <p>
              <strong>It's also possible to save an injured immature tooth with the proper care. Here are the two options your endodontist has to treat the injury.</strong>
            </p>
            <p>
              <strong>Apexogenesis</strong>
            </p>
            <p>
              Apexogenesis encourages the root to continue to develop after the pulp heals from the injury. The doctor covers the soft tissue with medication that promotes growth. It leaves the tip of the root or apex to continue to close as your child ages. During this time, the walls of the root canal thicken to offer better protection. When the procedure is successful, no further treatment is needed.
            </p>
            <hr/>
            <p><strong>Apexification</strong></p>
            <p>
              Apexification involves removing the unhealthy tissue from the roots of the tooth. The doctor adds medicine to the root to encourage hard tissue to form near its tip and offers a more substantial barrier for the root canal filling. Unfortunately, the root canal walls won't continue to develop after the procedure. As a result, the tooth will be susceptible to breaks. Restoration is needed following apexification to protect the tooth from further damage.
            </p>
            <p>
              For more information about traumatic injuries to the mouth or to schedule an appointment, contact our office at <a href="tel:3178464980">317-846-4980.</a>
            </p>
          </div>
        </Container>
      </section>

      <section id="traumatic-injuries-section-4">
        <ContactForm/>
      </section>
    </Layout>
  )
}

export default TraumaticInjuriesPage

export const query = graphql`
  query TraumaticInjuriesPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "traumatic-injuries-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
